// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import {getToken, clearToken, saveToken} from "../function/functions"; // Переконайтеся, що ця функція коректно отримує та парсить дані

// Створення контексту з дефолтними значеннями
export const AuthContext = createContext({
    authToken: null,
    user: null,
    login: () => {},
    logout: () => {}
});

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Перевірка наявності токену та даних користувача у localStorage при завантаженні
        const data = getToken();
        if (data && data.token) {
            setAuthToken(data.token);
            setUser({
                nicename: data.user_nicename,
                email: data.user_email,
                displayName: data.user_display_name
            });
        }
    }, []);

    const login = (data) => {
        // Збереження всіх даних у localStorage під ключем "jwtTokenDate"
        // localStorage.setItem("jwtTokenDate", JSON.stringify(data));
        saveToken(data)
        setAuthToken(data.token);
        setUser({
            nicename: data.user_nicename,
            email: data.user_email,
            displayName: data.user_display_name
        });
    };

    const logout = () => {
        // Видалення даних з localStorage
        // localStorage.removeItem("jwtTokenDate");
        clearToken()
        setAuthToken(null);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
