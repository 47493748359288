// gatsby-browser.tsx

import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import Layout from "./src/components/layout";
import { AuthProvider } from "./src/context/AuthContext"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
    // Передаємо props у Layout
    // console.log("GatsbyBrowser-element:", element);
    // console.log("GatsbyBrowser-props:", props);
    return (
        <AuthProvider>
            <Layout props={props}>
                {element}
            </Layout>
        </AuthProvider>
    )
}
