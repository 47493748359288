import React, { useState, useEffect, useContext } from 'react';
import { graphql, useStaticQuery, Link } from "gatsby";
import WrapHeader from '../../assets/styles/Header';
import Logo from '../../assets/image/Logo.svg';
import LogoWhite from '../../assets/image/Logo-white-header.svg';
import MenuPop from './MenuPop';
import Pop from "../Pop/Pop"

// Імпортуємо getCart, щоб знати, скільки товарів
import { getCart } from "../../function/cartFunctions"

// Імпортуємо AuthContext
import { AuthContext } from "../../context/AuthContext"

const Header = (props) => {
    const { allWpMenuItem } = useStaticQuery(graphql`
        query($menu : WpMenuLocationEnum = HEADER_MENU_TOP ) {
            allWpMenuItem(filter: { locations: { eq: $menu }, parentId: {eq: null} }) {
                nodes {
                    id
                    label
                    path 
                }
            }
        }
    `);

    const [open, setOpen] = useState(false);
    // const [isLogin, setIsLogin] = useState(getFromLocal('jwtTokenData') || false);
    // const [isSticky, setIsSticky] = useState(false);
    // const headerRef = useRef(null);
    // const sentinelRef = useRef(null);

    // Використання AuthContext
    const { authToken, user, logout } = useContext(AuthContext)

    // console.log('authToken>>>', authToken, user)

    const clickMenuPop = () => {
        setOpen(!open);
        open ? document.body.classList.remove('ovh') : document.body.classList.add('ovh');
    };


    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             setIsSticky(!entry.isIntersecting);
    //         },
    //         { root: null, threshold: 0, rootMargin: "0px" }
    //     );
    //
    //     if (sentinelRef.current) {
    //         observer.observe(sentinelRef.current);
    //     }
    //
    //     return () => {
    //         if (sentinelRef.current) {
    //             observer.unobserve(sentinelRef.current);
    //         }
    //     };
    // }, []);




    const [openPop, setOpenPop] = useState(false);
    const [positionPop, setPositionPop] = useState(false);
    const clickPop = (data) => {

        setOpenPop(!openPop);
        openPop ? document.body.classList.remove('ovh-pop') : document.body.classList.add('ovh-pop');

        setPositionPop(data)
        // console.log('clickPop >>>', data, setPositionPop)
    };


    // Додаємо стейт для кількості товарів у кошику
    const [cartCount, setCartCount] = useState(0);


    // ------------------------------------
    // Оновлюємо кількість товарів у кошику
    // при першому рендері та при зміні кошика
    // ------------------------------------
    useEffect(() => {
        // 1) При завантаженні
        updateCartCount();

        // 2) При кожній події "cartUpdated"
        const handleCartUpdated = () => {
            updateCartCount();
        };

        window.addEventListener('cartUpdated', handleCartUpdated);

        // При відмонтуванні компоненту видаляємо слухач
        return () => {
            window.removeEventListener('cartUpdated', handleCartUpdated);
        };
    }, []);


    // Функція зчитує кошик і оновлює стейт cartCount
    const updateCartCount = () => {
        const cartItems = getCart() || [];
        // Сума полів "step"
        const totalItems = cartItems.reduce((sum, item) => sum + (item.step || 1), 0);
        setCartCount(totalItems);
    };




    // // Функція зчитує кошик і оновлює стейт cartCount
    // const updateCartCount = () => {
    //     const cartItems = getCart() || [];
    //     // Якщо потрібна сума полів "step" — рахуємо їх
    //     // const totalItems = cartItems.reduce((sum, item) => sum + (item.step || 1), 0);
    //
    //     // Якщо достатньо просто показати кількість позицій
    //     const totalItems = cartItems.length;
    //     setCartCount(totalItems);
    // };


    return (
        <div className='WrapHeaderScroll'>
            {/* Sentinel для відстеження положення хедера */}

            <WrapHeader
                // ref={headerRef}
                // className={isSticky ? 'sticky ' : ''}
            >
                <div className="header border-hor">
                    <div className="row row-0 row-section border-ver">
                        <div className="col col-lg-auto">
                            <Link className="wrapLogo d-flex align-items-center" to='/'>
                                {props.colorRevers === 'colorRevers' ? <img src={LogoWhite} alt=""/> :
                                    <img src={Logo} alt=""/>}
                            </Link>
                        </div>
                        <div className="d-none d-sm-block col-sm-auto">
                            <div className="wrapHeaderIcon">
                                <div className='cursorNone btn btn1 btnIcon borderNone'>
                                    <div className='iconBG icon24 iconBG25'></div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg">
                            <div className="wrapMenu style-1 d-flex align-items-center">
                                <ul className='d-flex align-items-center'>
                                    {allWpMenuItem.nodes.map(item => (
                                        <li key={`top_menu_${item.id}`}>
                                            <Link to={item.path}>{item.label}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className='wrapHeaderIcon'>
                                <button onClick={() => clickPop('search')} aria-labelledby="button-label"
                                        className={`btn btn1 btnIcon borderNone ${openPop && positionPop === 'search' ? 'select' : ''}`} >
                                    <span className='iconBG icon24 iconBG37'></span>
                                </button>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <button onClick={() => clickPop('cart')} aria-labelledby="button-label"
                                        className={`btn btn1 btnIcon borderNone ${openPop && positionPop === 'cart' ? 'select' : ''}`}>
                                    <span className='iconBG icon24 iconBG36'></span>
                                    {cartCount > 0 && (
                                        <span id='cartCountBadge' className="cartCountBadge">{cartCount}</span>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">

                                {authToken ? (
                                    // Якщо користувач авторизований, показуємо посилання на /account/
                                    <Link to="/account/" className='btn btn1 btnIcon borderNone' aria-labelledby="button-label">
                                        <span className='iconBG icon24 iconBG35'></span>
                                    </Link>
                                ) : (
                                    // Якщо не авторизований, показуємо кнопку, яка відкриває попап
                                    <button
                                        onClick={() => clickPop('user')}
                                        className='btn btn1 btnIcon borderNone'
                                        aria-labelledby="button-label"
                                    >
                                        <span className='iconBG icon24 iconBG35'></span>
                                    </button>
                                )}

                                {/*{isLogin ? (*/}
                                {/*    // Якщо користувач авторизований, показуємо посилання на /account/*/}
                                {/*    <Link to="/account/" className='btn btn1 btnIcon borderNone'*/}
                                {/*          aria-labelledby="button-label">*/}
                                {/*        <span className='iconBG icon24 iconBG35'></span>*/}
                                {/*    </Link>*/}
                                {/*) : (*/}
                                {/*    // Якщо не авторизований, показуємо кнопку, яка відкриває попап*/}
                                {/*    <button onClick={() => clickPop('user')} className='btn btn1 btnIcon borderNone'*/}
                                {/*            aria-labelledby="button-label">*/}
                                {/*        <span className='iconBG icon24 iconBG35'></span>*/}
                                {/*    </button>*/}
                                {/*)}*/}
                                {/*<button onClick={() => clickPop('user')} className='btn btn1 btnIcon borderNone'>*/}
                                {/*    <span className='iconBG icon24 iconBG35'></span>*/}
                                {/*</button>*/}
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <button
                                    type="button"
                                    aria-labelledby="button-label"
                                    className={`btn btn1 btnIcon borderNone ${open ? 'select' : ''}`}
                                    onClick={() => clickMenuPop()}
                                >
                                    <span className='iconBG icon24 iconBG34'></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapHeader>
            <MenuPop open={open} triggerMenuPop={clickMenuPop}/>
            {/*<div ref={sentinelRef} style={{height: 1}}></div>*/}
            <Pop triggerPop={clickPop} positionPop={positionPop} openPop={openPop} />
        </div>

    );
};

export default Header;
